import React, { useState, useEffect } from "react";
import { getDataStokEd } from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import numeral from "numeral";

function ModalStokEd({ rank, onCloseModal }) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [dataStok, setDataStok] = useState([]);

  // Inisialisasi variabel total
  let totalUnitStok = 0;

  // Map dataArea dan tambahkan nilai ke total
  dataStok.forEach((list) => {
    totalUnitStok += parseInt(list.UnitStok);
  });

  // const totalUnitStok = dataStok.reduce(
  //   (total, list) => total + list.UnitStok,
  //   0
  // );

  useEffect(() => {
    showLoader();
    const payload = {
      rank: rank,
    };
    getDataStokEd(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataStok(res.data.data);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  }, []);

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  useEffect(() => {
    if (dataStok.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(document).ready(function() {
          $('#tableStokEd1').DataTable({
              "retrieve": true,
              "paging": false,
              "lengthChange": false,
              "searching": true,
              "ordering": true,
              "info": false,
              "autoWidth": false,
              "responsive": false,
          });
      });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [dataStok]);

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>

      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12 mx-[3%] my-[2%] max-w-8xl">
          {/* Modal content */}
          <div className="bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="overflow-y-auto overflow-x-auto max-h-[800px]">
              <div className="p-4 md:p-5 relative ">
                <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                  <h3 className="font-semibold text-lg mx-2 ">
                    STOK ED dan ED Pendek
                  </h3>

                  <div className="card-body mb-2">
                    <div>
                      <table
                        className="table table-bordered table-hover table-scroll mb-3"
                        id="tableStokEd1"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          overflow: "scroll",
                          display: "block",
                          width: "100%",
                          height: "525px",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#736098",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr>
                            <th
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#736098",
                              }}
                            >
                              No
                            </th>
                            <th
                              style={{
                                left: "60px",
                                zIndex: "10",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#736098",
                              }}
                            >
                              PRODUK
                            </th>
                            <th>NO BATCH</th>
                            <th>TANGGAL EXPIRED</th>
                            <th>UNIT</th>
                            <th>CABANG</th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "center" }}>
                          {dataStok.map((list, index) => {
                            // Konversi ExpDate ke format Date dan bandingkan dengan hari ini
                            const isExpired =
                              new Date(list.ExpDate) < new Date();

                            return (
                              <tr
                                key={index}
                                style={{
                                  color: isExpired ? "red" : "black", // Warna merah jika expired
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                    color: "#000000",
                                  }}
                                  width={"1%"}
                                >
                                  {index + 1}.
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "40px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  {list.NamaProduk}
                                </td>
                                <td>{list.BatchNo}</td>
                                <td>{list.ExpDate}</td>
                                <td>{list.UnitStok}</td>
                                <td>{list.Cabang}</td>
                              </tr>
                            );
                          })}
                        </tbody>

                        <tfoot className="bg-white border border-slate-800 font-bold sticky bottom-0 z-10">
                          <tr
                            style={{ textAlign: "right" }}
                            className="bg-white border border-slate-800 font-bold sticky bottom-0 z-10"
                          >
                            <td
                              colSpan={4}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Total
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              {formatToRupiah(totalUnitStok)}
                            </td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalStokEd;
