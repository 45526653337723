import APIClient from "../APIClient";

const token = localStorage.getItem("token");

export const getDataRatio = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/resume`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataRatioDetail = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/detail`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataRatioSubDetail = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/rekapUser`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataRatioArea = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/rekapUser`,
    payload,
    config
  ).then((res) => res);
  return result;
};
export const getDataRatioChanel = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/rekapUser`,
    payload,
    config
  ).then((res) => res);
  return result;
};
export const GetDetailSSTO = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/rekapProduct`,
    payload,
    config
  ).then((res) => res);
  return result;
};
export const GetNextTable = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/rekapProduct`,
    payload,
    config
  ).then((res) => res);
  return result;
};
export const GetNextTable2 = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/rekapProduct`,
    payload,
    config
  ).then((res) => res);
  return result;
};
export const GetNextTable3 = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/rekapProduct`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataChart = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/salesProduct`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataGrowth = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/growth`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataRatioOutletUser = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/userOutlet`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const GetDetailRank = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/report/rankSales`,
    payload,
    config
  ).then((res) => res);
  return result;
};
export const getTeamName = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/masterEntry`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const GetUserEntry = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/userEntry`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const PostEntry = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/saveEntryPl`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const PostProduct = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/saveEntryUsr`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getBreakTarget = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/breakTarget`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getProductAch = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/productAch`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getUserAch = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/userAch`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getListChoice = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/listChoice`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getlistDropDown = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/target/listDropDown`,
    payload,
    config
  ).then((res) => res);
  return result;
};
export const getDataLog = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/log/activity`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getListChoiceSubdist = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/master/subdist`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataSubdist = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/stock/subdist`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataProduk = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/stock/subdist`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataBatch = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(
    `/api/stock/subdist`,
    payload,
    config
  ).then((res) => res);
  return result;
};

export const getDataStokEd = async (payload) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.post(`/api/stock/ed`, payload, config).then(
    (res) => res
  );
  return result;
};
